@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar {
	display: none;
} */

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

body {
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}
.bg-video-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	/* background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat
		center center/cover; */
}
video {
	min-width: 100%;
	min-height: 100vh;
	/* z-index: 1; */
}

@media (width <= 578px) {
	#main-video {
		display: none !important;
	}
}

.overlay {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	/* background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.7) 50%); */
	/* background-size: 3px 3px; */
	z-index: 2;
}
h1 {
	text-align: center;
	color: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 3;
	max-width: 400px;
	width: 100%;
	height: 50px;
}

.smooth {
	-webkit-transition: max-height, 0.8s ease-in-out;
	transition: max-height, 0.8s ease-in-out;
	overflow-y: hidden;
	overflow-x: hidden;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.menu {
	animation: fadeIn 0.2s ease-in-out;
}

.chart-bg-img {
	background: linear-gradient(90deg, #0000002b, rgba(0, 0, 0, 0.22) 103.56%),
		url('./assets//images//chart-bg.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: top;
}

/* table {
	border-collapse: collapse;
	width: 100%;
}

table td,
table th {
	padding: 8px;
} */

table tr:nth-child(even) {
	background-color: #f2f2f2;
}

/* table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: white;
	color: black;
}

table {
	border-collapse: collapse;
	width: 100%;
}

th,
td {
	text-align: left;
	padding: 8px;
} */
